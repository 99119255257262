@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

/* base styles */
:root{
  --accent-colour: #fff; /*or #00BCD4 #FF7043*/
  --darkcontrast-colour: #0F1926; /* #2C3E50*/
  --jolly-colour: #221813;
  --jolly-backgroundColour: rgba(34, 24, 19, .5);
  --frigg-colour: #ead9c5; 
}
* {
  margin: 0;
  font-family: "Lato";
  color: var(--accent-colour);
  box-sizing: border-box;
}
spintext{
  color: var(--darkcontrast-colour);
}

.App {
text-align:center;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
h2 {
  color: var(--accent-colour);
}
p {
  color: var(--accent-colour);
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
}
.opaquebox {
  padding: 5px;
  line-height: 1.6;
  font-size: 18px;
  border: 3px solid var(--darkcontrast-colour);
  border-radius: 0.5em;
  background-color: rgba(15, 25, 38,.80);
  color: var(--accent-colour);
  margin: 10px;
}
.portfolioPage {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 300px;
    justify-content: center;
    margin: 8px;
    position: relative;
    text-decoration: none;
    transition: all .5s;
    width: 300px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.about {
  text-align: left;
  display: flex;
  justify-content: space-around;
  margin-top: 8vh;
  max-width: 100vw;
  width: 100%;
}
.content {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  max-width: 100vw;
  width: 100%;
}
img {
  height: 40vh;
  border-radius: 0.5em;
}
.portfolioImg {
  height: 250px;
  width: 300px;
  border-radius: 0.5em;
}
.contentBlock {
  align-items: center;
  display: flex;
  margin: 10px;
}
.homeContent {
  position: absolute; 
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 20%; 
}

.portContent {
  position: absolute;
  margin-top: 13%; 
  left: 10%;
}

.aboutContent {
  flex-direction:column;
  height: fit-content;
  padding: 25px;
  position: relative;
}

.navbarBackground {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -1;
  
}
.backgroundContainer {
  background-image: url(Techy\ BG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -1;
}

.fourofourBackground {
  background-image: url(Jolly\ og\ Frigg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -1
}
.navbar {
  background-color: var(--darkcontrast-colour);
  border-bottom: 10px solid var(--accent-colour);
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  /* padding order: top, right, bottom, left  */
  padding: 0.5% 2% 0.5%;
  position: fixed;
  width: 100%;
  z-index: 10;
  align-items: baseline;
}

.navbar h1 {
  color: var(--accent-colour);
  font-size: 2em;
  display: flex;
}
.navbar .links {
  margin-left: auto;
  right: 20px;
  font-size: 1em;
}
.navbar a {
  margin-left: 16px;
  color: var(--accent-colour);
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #00BCD4;
}

.sections {
  padding-left: 70%;
  align-items: baseline;
  display: flex;
}
header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

li {
  padding: 8px;
  transition: all linear 0.3s;
}

.dropdown-menu {
  border-radius: 0.5em;
  position: absolute;
  text-align: start;
  background: var(--darkcontrast-colour);
  /* margin-top: 0.5em; Adjust this value if needed */
  margin-left: 0.5em; /* Aligns dropdown closer to the trigger element */
  /* padding: -0.5em; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
}

.image-link {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.image-link img {
  display: block
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 0.5em;
}
.text {
  color: white;
  font-size: 30px;
  text-align: center;
  transition: 0.3s;
}

.hidden {
  opacity: 0;
}

.hovered .overlay {
  background-color: transparent;
}

.hovered .text {
  opacity: 0;
}
.image-row {
  display: flex; /* Display images in a row */
  overflow: hidden; /* Hide any overflowing content */
}
.skills-container {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  padding: 1em;
  justify-content: center;  
}

.skill-badge {
  background: var(--darkcontrast-colour);
  color: var(--accent-colour);
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-weight: bold;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.skill-badge:hover {
  transform: scale(1.1);
}

